<template>
  <div class="level-card-wrapper">
    <div v-if="!show" class="showMore-wrapper" @click="showList" data-testid="showMore">
      <img src="@/assets/images/iblevel/showMore.png" />
    </div>
    <div v-else class="content">
      <el-row :gutter="10">
        <el-col>
          <el-row type="flex" justify="center" :gutter="10" style="flex-wrap:wrap;">
            <div class="content-centered">
              <img
                :src="
                  bodyDirection === 'ltr'
                    ? require('@/assets/images/iblevel/arrowLeft.png')
                    : require('@/assets/images/iblevel/arrowRight.png')
                "
                class="content-image"
                @click="getPrevPageArr"
                data-testid="arrowLeft"
              />
            </div>
            <el-col v-for="item in chunkedList[curPage]" :key="item.id" class="card-content-wrapper">
              <ibCard
                :name="item.name"
                :accNumber="item.account"
                :isActiveNo="isActiveNo"
                @choose="chooseIB(item, level)"
              ></ibCard>
            </el-col>
            <div class="content-centered">
              <img
                :src="
                  bodyDirection === 'ltr'
                    ? require('@/assets/images/iblevel/arrowRight.png')
                    : require('@/assets/images/iblevel/arrowLeft.png')
                "
                class="content-image"
                @click="getNextPageArr"
                data-testid="arrowRight"
              />
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ibCard from '@/components/ibLevel/ibCard';

export default {
  components: {
    ibCard: ibCard
  },
  name: 'levelList',
  props: {
    level: Number,
    ibList: Array
  },
  data() {
    return {
      hasNext: true,
      hasPrev: false,
      curPage: 0,
      chunkedList: [],
      size: 6,
      isActiveNo: null,
      show: false,
      bodyDirection: null
    };
  },
  computed: {
    hasNextFlag() {
      return this.curPage < this.chunkedList.length - 1;
    },
    hasPrevFlag() {
      return this.curPage !== 0;
    },
    sizeFlag() {
      const matchesMobile = window.matchMedia('(max-width: 1024px)').matches;
      const sizes = {
        matchesMobile: 3
      };
      return matchesMobile ? sizes['matchesMobile'] : this.size;
    }
  },
  watch: {
    ibList: {
      immediate: true,
      handler(val) {
        this.curPage = 0;
        //if items more than 24, el-col has 2 lines (8x2). 8 per line.
        //if (this.ibList.length >= 24) this.size = 16;
        this.chunkedList = this.chunk(val, this.sizeFlag);
        if (this.level === 1 && this.chunkedList[0] && this.chunkedList[0][0] && this.chunkedList[0][0].account) {
          this.isActiveNo = this.chunkedList[0][0].account;
          this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
        }
        if (this.level === 1) {
          this.show = true;
        }
      }
    }
  },
  methods: {
    showList() {
      this.show = !this.show;
    },
    chunk(arr, size) {
      return arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
    },
    getNextPageArr() {
      if (this.hasNextFlag) {
        this.curPage++;
      }
    },
    getPrevPageArr() {
      if (this.hasPrevFlag) {
        this.curPage--;
      }
    },
    chooseIB(item, level) {
      this.isActiveNo = item.account;
      this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/iblevel.scss';
</style>
