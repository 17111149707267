<template>
  <div @click="chooseHandler" class="ib-card-wrapper">
    <el-card :body-style="{ padding: '0px' }" class="ib-card" :class="{ 'ib-card__active': isActiveNo === accNumber }">
      <div class="ib-image-wrapper">
        <img
          :src="
            isActiveNo === accNumber
              ? require(`@/assets/images/iblevel/profileActive.png`)
              : require(`@/assets/images/iblevel/profile.png`)
          "
          class="ib-image"
        />
      </div>
      <el-row class="ib-card-title" :class="{ 'ib-card-title__active': isActiveNo === accNumber }">
        <span>{{ name }}</span>
      </el-row>
      <el-row class="ib-card-subtitle" :class="{ 'ib-card-subtitle__active': isActiveNo === accNumber }">
        <span>{{ accNumber }}</span>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ibCard',
  props: {
    name: String,
    accNumber: Number,
    isActiveNo: Number
  },
  data() {
    return {};
  },
  methods: {
    chooseHandler() {
      this.$emit('choose');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/ibcard.scss';
</style>
